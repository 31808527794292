<template>
    <div class="confirm-mobile">
        <div class="mobile-img">
            <img src="@/assets/images/elements/done change password.png" alt="Mobile">
        </div>
        <h5>تم تغيير كلمة المرور بنجاح</h5>
        <p>لقد قمنا بتغيير كلمة المرور بنجاح يمكنك الان تسجيل الدخول مرة آخري إلي حسابك.</p>
        <div class="confirm-input">
            <button @click="$router.push('/login').catch(() => {})">تسجيل الدخول</button>
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            password: '',
            confirmPassword: '',
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/vuexy/_login.scss';
</style>